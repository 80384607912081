<template>
  <div class="col-md-12">
    <div class="row">
      <div class="col-8 pa-0">
        <div class="earning">
          <div class="icon-box">
            <div class="icon-img">
              <img src="/images/photos/earning.svg" alt="" />
            </div>
            <v-menu open-on-hover top offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="#5E35B1" dark v-bind="attrs" v-on="on">
                  <span class="material-icons"> more_horiz </span>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in bills" :key="index">
                  <v-list-item-title @click="getTransaction(item, undefined)">{{
                    item.title
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <h1>
            {{
              transactions.invoice_amount
                ? Math.round(transactions.invoice_amount, 2).nepaliCurrency()
                : '-'
            }}
          </h1>
          <p>Total Bill Generate</p>
        </div>

        <div class="order">
          <div class="icon-box">
            <div class="icon-img">
              <span class="material-icons"> local_mall </span>
            </div>
            <v-menu open-on-hover top offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="#1D88E5" dark v-bind="attrs" v-on="on">
                  <span class="material-icons"> more_horiz </span>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in collections" :key="index">
                  <v-list-item-title @click="getTransaction(undefined, item)">{{
                    item.title
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <h1>
            {{
              transactions.transaction_amount
                ? Math.round(
                    transactions.transaction_amount,
                    2
                  ).nepaliCurrency()
                : '-'
            }}
          </h1>
          <p>Total Collection</p>
        </div>
      </div>
      <div class="col-4 pa-0">
        <div class="income">
          <div class="icon-box">
            <div class="icon-img">
              <span class="material-icons"> table_chart </span>
            </div>
            <div class="text">
              <h1>
                {{
                  transactions.advance_amount
                    ? Math.round(
                        transactions.advance_amount,
                        2
                      ).nepaliCurrency()
                    : '-'
                }}
              </h1>
              <p>Total Advance</p>
            </div>
          </div>
        </div>

        <div class="income-cart">
          <div class="icon-box">
            <div class="icon-img">
              <span class="material-icons"> storefront </span>
            </div>
            <div class="text">
              <h1>
                {{
                  (totalDueAmount.currentStudentsDue + totalDueAmount.leftStudentDue).nepaliCurrency()
                }}
              </h1>
              <p>
                (Current / Left) Dues ({{
                  totalDueAmount.currentStudentsDue.nepaliCurrency()
                }}
                /
                {{
                  totalDueAmount.leftStudentDue.nepaliCurrency()
                }})
              </p>
            </div>
            <!-- <div class="text">
              <h1>
                {{
                  transactions.due_amount
                    ? Math.round(transactions.due_amount).nepaliCurrency()
                    : '-'
                }}
              </h1>
              <p>
                (Previous/Others) Dues ({{
                  transactions.previous_due
                    ? Math.round(transactions.previous_due).nepaliCurrency()
                    : '-'
                }}
                /
                {{
                  transactions.other_due
                    ? Math.round(transactions.other_due).nepaliCurrency()
                    : '-'
                }})
              </p>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from '@/library/Mixins';
import { getBatch } from '../../../../library/helpers';
export default {
  created() {},
  mixins: [Mixins],
  data() {
    return {
      bills: [
        { title: 'Today', value: 'bill_today' },
        { title: 'Weekly', value: 'bill_week' },
        { title: 'Monthly', value: 'bill_month' },
        { title: 'Yearly', value: 'bill_year' },
      ],
      collections: [
        { title: 'Today', value: 'collection_today' },
        { title: 'Weekly', value: 'collection_week' },
        { title: 'Monthly', value: 'collection_month' },
        { title: 'Yearly', value: 'collection_year' },
      ],
      transactions: [],
      totalDueAmount: {
        currentStudentsDue: 0,
        leftStudentDue: 0
      }
    };
  },
  mounted() {
    this.getTotalDue();
    var date = new Date();
    date.setDate(date.getDate() - 1);
    let today = new Date().toISOString().slice(0, 10);
    const yesterdayDay =
      date.getDate().toString().length == 1
        ? 0 + date.getDate().toString()
        : date.getDate();
    const yesterdayMonth =
      (date.getMonth() + 1).toString.length == 1
        ? 0 + (date.getMonth() + 1)
        : date.getMonth() + 1;

    let yesterday =
      date.getFullYear() + '-' + yesterdayMonth + '-' + yesterdayDay;
    this.$localStorage.removeFromStorage('transactions_' + yesterday);
    let data = this.$localStorage.getFromStorage('transactions_' + today);
    if (!data) {
      this.getTransaction();
    } else {
      this.transactions = data;
    }
  },
  methods: {
    getTransaction(bill = undefined, collection = undefined) {
      let batchStart = getBatch().start;
      let batchEnd = getBatch().end;
      let today = new Date().toISOString().slice(0, 10);
      let bill_value, collection_value;
      if (bill === undefined) {
        bill_value = 'bill_month';
      } else {
        bill_value = bill.value;
      }
      if (collection === undefined) {
        collection_value = 'collection_month';
      } else {
        collection_value = collection.value;
      }
      this.$rest
        .get(
          `/api/report/transaction-history?filterBill=${bill_value}&filterCollection=${collection_value}&start=${batchStart}&end=${batchEnd}`
        )
        .then(({ data }) => {
          this.transactions = data;
          this.$localStorage.setToStorage('transactions_' + today, data);
        });
    },
    getTotalDue(){
      if(localStorage.getItem("total_due_amount") === null){
        this.$rest.get('/api/report/students-total-due')
        .then((data)=>{
          this.totalDueAmount.currentStudentsDue = data.data.currentStudentsDue;
          this.totalDueAmount.leftStudentDue = data.data.leftStudentDue;
          window.localStorage.setItem('total_due_amount', JSON.stringify(data.data));
        })
      }else{
        let get_total_due_amount = JSON.parse(window.localStorage.getItem('total_due_amount'));
        this.totalDueAmount.currentStudentsDue = get_total_due_amount.currentStudentsDue;
        this.totalDueAmount.leftStudentDue = get_total_due_amount.leftStudentDue;
      }
    }
  },
};
</script>

<style scoped>
.v-list-item:hover {
  background-color: rgb(241, 241, 241);
  cursor: pointer;
}
</style>
